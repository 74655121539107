import {Component, HostBinding, Input} from '@angular/core';

@Component({
    selector: '[appUnderProcessing]',
    template: `
        <fal *ngIf="loading" i="circle-notch" class="loading-icon" [spin]="true"></fal>
        <ng-content *ngIf="!loading || !hideContentWhileProcessing"></ng-content>
    `,
    styleUrls: ['./under-processing.component.scss'],
})
export class UnderProcessingComponent {
    @Input('appUnderProcessing') @HostBinding('attr.loading') loading: boolean;
    @Input() disabled: boolean;
    @Input() hideContentWhileProcessing = false;

    @HostBinding('attr.disabled') get disabledAttr() {
        return this.loading || this.disabled || null;
    }
}
